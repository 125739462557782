<template>
    <div>
        <!--
            Skiplinks link to landmarks in the website.
            Don't forget to add the IDs below to your custom elements.
            Keep in mind there can only be 1 <main> and 1 <footer> component in the context of the body
        -->
        <SkipLinks />
        
        <TheNavigation :apply-link />

        <div class="layout">
            <div class="layout__main">
                <slot />
            </div>
        </div>

        <TheFooter />

        <ClientOnly>
            <!-- fixme -->
            <TheCookiebar />
            <TheLoadingIndicator />
        </ClientOnly>
    </div>
</template>

<script setup>
const BGLF_AANVRAAG_SLUG = 'aanvragen',
    DOEN_AANVRAAG_SLUG = 'aanvraag-doen',
    DOEN_EN_AANVRAAG_SLUG = 'applications';

const appConfig = useAppConfig();
const route = useRoute();
const { locale } = useI18n();
const { isBglf } = useMultisite();

useFavicon();

const uri = computed(() => route?.params?.pathMatch ?? '');

const applyLink = computed(() => {
    if (uri.value === BGLF_AANVRAAG_SLUG) {
        return '/mijn/aanvragen';
    }

    if (uri.value === DOEN_AANVRAAG_SLUG) {
        return 'https://aanvraag.doen.nl';
    }

    if (uri.value === DOEN_EN_AANVRAAG_SLUG) {
        return 'https://aanvraag.doen.nl?lang=en_US';
    }

    if (isBglf.value) {
        return `/${BGLF_AANVRAAG_SLUG}`;
    }

    if (locale.value === 'en') {
        return `/${DOEN_EN_AANVRAAG_SLUG}`;
    }

    return `/${DOEN_AANVRAAG_SLUG}`;
});

const i18nHead = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true
});

useHead({
    htmlAttrs: {
        ...i18nHead.value.htmlAttrs,
    },
    bodyAttrs: {
        'data-theme': appConfig.theme
    }
});
</script>
